import { render, staticRenderFns } from "./UIServerUrl.vue?vue&type=template&id=6c78050d&scoped=true&"
import script from "./UIServerUrl.js?vue&type=script&lang=js&"
export * from "./UIServerUrl.js?vue&type=script&lang=js&"
import style0 from "../UIServerStyle.styl?vue&type=style&index=0&id=6c78050d&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c78050d",
  null
  
)

export default component.exports