import { render, staticRenderFns } from "./Simple.vue?vue&type=template&id=344d691f&scoped=true&"
import script from "./Simple.js?vue&type=script&lang=js&"
export * from "./Simple.js?vue&type=script&lang=js&"
import style0 from "./../../../UIServerStyle.styl?vue&type=style&index=0&id=344d691f&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344d691f",
  null
  
)

export default component.exports