<template>
  <v-tooltip v-model="show" bottom>
    <template #activator="{ attrs }">
      <v-hover v-model="show" open-delay="1000">
        <v-btn
          v-bind="attrs"
          :disabled="isNotActive"
          :dark="!isNotActive"
          :light="isNotActive"
          class="choice-btn mt-1 text-left"
          color="primary"
          depressed
          small
          @click="onChoice(item.value)">
          <div ref="container" class="text-truncate">
            <span v-if="!disableNumbering" ref="numbering" v-text="`${index + 1}.`" />
            <span ref="text" v-text="item.title" />
          </div>
        </v-btn>
      </v-hover>
    </template>
    <span>{{ item.title }}</span>
  </v-tooltip>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'ChoiceBtn',
    props: {
      isNotActive: Boolean,
      item: Object,
      params: Object,
      index: Number,
    },
    data: () => ({
      show: false,
      ignoreNextTooltip: false,

      messagesContainer: null,
    }),
    computed: {
      ...mapGetters('theme', ['textColorsHash']),

      disableNumbering() {
        return this.params.disableNumbering !== undefined ? this.params.disableNumbering : false;
      },
    },
    watch: {
      isNotActive() {
        setTimeout(() => {
          this.show = false;
        }, 1000);
      },
      show(value) {
        if (this.ignoreNextTooltip) {
          this.ignoreNextTooltip = false;
          this.show = false;
        }
        if (value) {
          window.addEventListener('mousemove', this.closeTooltip);
          this.messagesContainer?.addEventListener('scroll', this.closeTooltip);
        } else {
          window.removeEventListener('mousemove', this.closeTooltip);
          this.messagesContainer?.removeEventListener('scroll', this.closeTooltip);
        }
      },
    },
    async mounted() {
      this.messagesContainer = document.querySelector('.messages-container');
    },
    methods: {
      ...mapActions('sessionView', ['sendMessage']),
      onChoice(value) {
        this.sendMessage({ message: value });
        this.show = false;
        this.ignoreNextTooltip = true;
      },
      closeTooltip() {
        this.show = false;
      },
    },
  };
</script>

<style lang="stylus">
  .choice-btn {
    display: block;
    min-width: 60px !important;
    max-width: 100%;
    border-radius: 8px;
    text-transform: none !important;
    font-size: 0.9rem !important;
    font-weight: 500;
    letter-spacing: 0;
  }

  .choice-btn > .v-btn__content {
    justify-content: left !important;
  }

  .text-truncate {
    &::after {
      content: '';
      display: block;
    }
  }
</style>
